/*
 * Description of what this file is for
 *
 * @package  de.bistum-passau.www
 * @author Julia Kaupert
 * navFooterMeta
 */

const navFooterMeta = {
  cfg: {
    name: 'navFooterMeta',
    selectors: {
      body: 'body',
      metaNavList: '.m-navFooterMeta__list--level-1',
      metaNavListSubsite: '.m-navFooterMetaSubSite__list--level-1',
      cookieTrigger: '.js-cookieTrigger',
    },
    classes: {
      noCookie: 'noCookie',
      metaLink: 'm-navFooterMeta__listItem  m-navFooter__listItem--level-1',
      metaLinkSubSite:
        'm-navFooterMetaSubSite__listItem  m-navFooterMetaSubSite__listItem--level-1',
    },
    el: {
      body: undefined,
    },
  },

  init() {
    this.storeCookieTriggerEl();
    if (this.cfg.el.$cookieTrigger) {
      // Attaching event listeners if the cookie settings link is present
      this.attachEventListeners();
    } else {
      // Injecting the cookie consent settings provided by the UserCentrics layer
      this.injectCookieTriggerLink();
    }
  },
  storeCookieTriggerEl() {
    this.cfg.el.$cookieTrigger = document.querySelector(
      `footer ${this.cfg.selectors.cookieTrigger}`,
    );
  },
  attachEventListeners() {
    this.cfg.el.$cookieTrigger.addEventListener('click', (event) => {
      event.preventDefault();
      event.stopPropagation();

      // Triggers the cookie consent layer provided by UserCentrics
      if (window.UC_UI) {
        window.UC_UI.showSecondLayer();
      }
    });
  },
  injectCookieTriggerLink() {
    const metaNavList = document.querySelector(this.cfg.selectors.metaNavList);
    const subSiteMetaNavList = document.querySelector(this.cfg.selectors.metaNavListSubsite);

    const classes = metaNavList ? this.cfg.classes.metaLink : this.cfg.classes.metaLinkSubSite;
    const target = metaNavList || subSiteMetaNavList;

    target.insertAdjacentHTML(
      'beforeend',
      `<li class="${classes} js-cookieTrigger"><a class="${
        subSiteMetaNavList ? 'm-navFooterMetaSubSite__link' : ''
      }">Cookie-Einstellungen</a></li`,
    );

    this.storeCookieTriggerEl();
    this.attachEventListeners();
  },
};

export default navFooterMeta;
