/*
 * @package  de.bistum-passau.www
 * @author Theresa Obermayr
 * searchChurchesSearch
 */

import { createApp } from 'vue';
import AutocompleteSearch from './_component.vue';

const searchChurchesSearch = {
  cfg: {
    name: 'searchChurchesSearch',
    selectors: {
      search: 'o-searchChurchesSearch',
    },
    el: {
      $search: undefined,
    },
  },

  setElements() {
    this.cfg.el.$search = document.querySelector(this.cfg.selectors.search);
  },

  init() {
    const vueSearchElem = document.querySelector('#vueSearchChurches');

    if (vueSearchElem) {
      const isSmallVariant = vueSearchElem.dataset.variant === 'small';
      const isMediumVariant = vueSearchElem.dataset.variant === 'medium';
      const selectedChurch = vueSearchElem.dataset.selected ? vueSearchElem.dataset.selected : '';
      const churchIds = vueSearchElem.dataset.ids
        ? vueSearchElem.dataset.ids.split(',').map((id) => parseInt(id))
        : undefined;

      const app = createApp(AutocompleteSearch, {
        small: isSmallVariant,
        medium: isMediumVariant,
        preselected: selectedChurch,
        churchids: churchIds,
      }).mount('#vueSearchChurches');
    }
  },
};

export default searchChurchesSearch;
